export const typeData = {
    project: {
        text: { en: "EN)프로젝트 의뢰", kr: "프로젝트 의뢰" },
        id: "request-project",
    },
    etc: {
        text: { en: "EN)기타 의뢰", kr: "기타 의뢰" },
        id: "request-etc",
        tooltipText: {
            en: "EN)언론 홍보, 인터뷰 요청, 세미나 요청, 마케팅 업무, 개발사 협조 등을 의뢰하실 수 있습니다.",
            kr: "언론 홍보, 인터뷰 요청, 세미나 요청, 마케팅 업무, 개발사 협조 등을 의뢰하실 수 있습니다.",
        },
    },
};

export const rangeBrandData = {
    brand_strategy: {
        text: { en: "EN)브랜드 전략 포함", kr: "브랜드 전략 포함" },
        id: "brand-strategy",
        tooltipText: {
            en: (
                <>
                    EN)브랜드 아이덴티티 시스템 구축
                    <br />
                    (예시 : 브랜드 핵심가치, 브랜드 에센스 등)
                    <br />
                    <br />위 항목은 개별 항목으로 프로젝트를 진행하지 않습니다.
                </>
            ),
            kr: (
                <>
                    브랜드 아이덴티티 시스템 구축
                    <br />
                    (예시 : 브랜드 핵심가치, 브랜드 에센스 등)
                    <br />
                    <br />위 항목은 개별 항목으로 프로젝트를 진행하지 않습니다.
                </>
            ),
        },
    },
    brand_name_slogan: {
        text: {
            en: "EN)브랜드 버벌자산 구축 포함",
            kr: "브랜드 버벌자산 구축 포함",
        },
        id: "brand-name-slogan",
        tooltipText: {
            en: (
                <>
                    EN)브랜드 버벌자산 구축
                    <br />
                    (예시 : 상호 개발, 브랜드 네임 개발, 브랜드 태그라인, 브랜드
                    스토리 등)
                    <br />
                    <br />위 항목은 개별 항목으로 프로젝트를 진행하지 않습니다.
                </>
            ),
            kr: (
                <>
                    브랜드 버벌자산 구축
                    <br />
                    (예시 : 상호 개발, 브랜드 네임 개발, 브랜드 태그라인, 브랜드
                    스토리 등)
                    <br />
                    <br />위 항목은 개별 항목으로 프로젝트를 진행하지 않습니다.
                </>
            ),
        },
    },
    brand_identity_design: {
        text: {
            en: "EN)브랜드 아이덴티티 디자인",
            kr: "브랜드 아이덴티티 디자인",
        },
        id: "brand-identity-design",
        tooltipText: {
            en: (
                <>
                    EN)브랜드 아이덴티티 요소 구축
                    <br />
                    (예시 : 로고, 컬러, 키 비주얼, 타이포그래피, 아이콘,
                    포토그래피 등)
                    <br />
                    <br />위 항목은 개별 항목으로 프로젝트를 진행하지 않습니다.
                </>
            ),
            kr: (
                <>
                    브랜드 아이덴티티 요소 구축
                    <br />
                    (예시 : 로고, 컬러, 키 비주얼, 타이포그래피, 아이콘,
                    포토그래피 등)
                    <br />
                    <br />위 항목은 개별 항목으로 프로젝트를 진행하지 않습니다.
                </>
            ),
        },
    },
    media_concept_design: {
        text: {
            en: "EN)브랜드 경험 디자인",
            kr: "브랜드 경험 디자인",
        },
        id: "concept-design",
        tooltipText: {
            en: (
                <>
                    EN)오프라인 브랜드 경험 디자인
                    <br />
                    (예시 : 명함, 쇼퍼백, 리플릿, 패키지 등)
                    <br />
                    <br />
                    온라인 브랜드 경험 디자인
                    <br />
                    (예시 : 앱아이콘, 스플래시, SNS, AD 등)
                </>
            ),
            kr: (
                <>
                    오프라인 브랜드 경험 디자인
                    <br />
                    (예시 : 명함, 쇼퍼백, 리플릿, 패키지 등)
                    <br />
                    <br />
                    온라인 브랜드 경험 디자인
                    <br />
                    (예시 : 앱아이콘, 스플래시, SNS, AD 등)
                </>
            ),
        },
    },
};
export const rangeUIData = {
    web: {
        text: { en: "EN)웹", kr: "웹" },
        id: "range-ui-web",
    },
    app: {
        text: { en: "EN)앱", kr: "앱" },
        id: "range-ui-app",
    },
    backend_development: {
        text: { en: "EN)백엔드 개발", kr: "백엔드 개발" },
        id: "range-ui-backend",
    },
    frontend_development: {
        text: { en: "EN)프론트엔드 개발", kr: "프론트엔드 개발" },
        id: "range-ui-frontend",
    },
};

export const rangeProjectData = {
    consulting: {
        text: { en: "EN)컨설팅", kr: "컨설팅" },
        id: "range-project-consulting",
        tooltipText: {
            en: "EN)컨설팅을 하게 되면 리서치를 통해 시안 2종과 프로토타입이 산출물로 나옵니다.",
            kr: "컨설팅을 하게 되면 리서치를 통해 시안 2종과 프로토타입이 산출물로 나옵니다.",
        },
    },
    building: {
        text: { en: "EN)신규/구축", kr: "신규/구축" },
        id: "range-project-new",
        // tooltipText: {
        //     en: "EN)툴팁 내용입니다.",
        //     kr: "툴팁 내용입니다.",
        // },
    },
    solution: {
        text: { en: "EN)개선 구축", kr: "개선 구축" },
        id: "range-project-improve",
        // tooltipText: { en: "EN)Tooltip", kr: "Tooltip" },
    },
    maintenance: {
        text: { en: "EN)유지 보수(운영)", kr: "유지 보수(운영)" },
        id: "range-project-manage",
        // tooltipText: { en: "EN)Tooltip", kr: "Tooltip" },
    },
    etc: {
        text: { en: "EN)기타", kr: "기타" },
        id: "range-project-etc",
    },
};

// 키값이 서버로 넘어가는 값
export const rangeKindData = {
    media_promotion: {
        text: { en: "EN)언론홍보", kr: "언론홍보" },
        id: "range-kind-ad",
    },
    interview: {
        text: { en: "EN)인터뷰 요청", kr: "인터뷰 요청" },
        id: "range-kind-interview",
    },
    seminar: {
        text: { en: "EN)세미나 요청", kr: "세미나 요청" },
        id: "range-kind-seminal",
    },
    marketing: {
        text: { en: "EN)마케팅 업무", kr: "마케팅 업무" },
        id: "range-kind-marketing",
    },
    cooperation: {
        text: { en: "EN)개발사 협조", kr: "개발사 협조" },
        id: "range-kind-corp",
    },
    purchase_request: {
        text: { en: "EN)구매 문의", kr: "구매 문의" },
        id: "range-kind-purchase",
        tooltipText: {
            en: (
                <>
                    EN)플러스엑스 자체 제작 사원증 케이스 구매를 문의하실 수
                    있습니다. (
                    <a
                        href="mailto:object@plus-ex.com"
                        target={"_blank"}
                        rel="noreferrer noopener"
                    >
                        object@plus-ex.com
                    </a>
                    참고)
                </>
            ),
            kr: (
                <>
                    플러스엑스 자체 제작 사원증 케이스 구매를 문의하실 수
                    있습니다. (
                    <a
                        href="mailto:object@plus-ex.com"
                        target={"_blank"}
                        rel="noreferrer noopener"
                    >
                        object@plus-ex.com
                    </a>
                    참고)
                </>
            ),
        },
    },
    etc: {
        text: { en: "EN)기타", kr: "기타" },
        id: "range-kind-etc",
    },
};

export const requestPriceData = {
    150: {
        text: { en: "EN)1 ~ 2억", kr: "1 ~ 2억" },
        id: "between-10-20",
    },
    250: {
        text: { en: "EN)2 ~ 3억", kr: "2 ~ 3억" },
        id: "between-20-30",
    },
    350: {
        text: { en: "EN)3 ~ 4억", kr: "3 ~ 4억" },
        id: "between-30-40",
    },
    450: {
        text: { en: "EN)4 ~ 5억", kr: "4 ~ 5억" },
        id: "between-40-50",
    },
    750: {
        text: { en: "EN)5억 이상", kr: "5억 이상" },
        id: "over-50",
    },
};
